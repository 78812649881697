<template>
  <div class="register pass-rest class-btn">
    <div class="register-logo clearfix">
      <div class="logo-pic">
        <img src="@/assets/image/login/lg1.png">
      </div>
    </div>
    <div class="center-opcity"></div>
    <div class="register-cont">
      <div class="pos-tit">
        <div class="tabe-tit1">中科可控供需协同平台</div>
        <div class="tabe-tit2">一个平台、多方共赢</div>
      </div>
      <div class="register-tit clearfix">
        <span class="left-span"><span class="res-line"></span>密码重置</span>
        <span class="right-span" @click="GoLogin"><span>有密码，</span>去登录></span>
      </div>
      <div class="register-news">
        <el-form v-show="fistNews" :model="passWordRuleForm" :rules="passWordNewsRules" ref="passWordRuleForm" class="demo-ruleForm">
          <div class="yz-flot">
            <el-form-item class="ver-left yz-ver-left" prop="verification">
              <el-select v-model="passWordRuleForm.verification" placeholder="请选择验证方式">
                <el-option label="短信验证" value="短信验证"></el-option>
                <el-option label="邮箱验证" value="邮箱验证"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="passWordRuleForm.verification == '短信验证'" class="yz-user-phong"  :class="{styleName: isStylePhoneCheck}" prop="userPhone">
              <el-input @focus="PhoneCheckFocus" @blur="PhoneCheckBlur" v-model="passWordRuleForm.userPhone" maxlength="18" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item v-else-if="passWordRuleForm.verification == '邮箱验证'" class="yz-user-phong" :class="{styleName: isStyleMailCheck}" prop="userEmail">
              <el-input @focus="MailCheckFocus" @blur="MailCheckBlur" v-model="passWordRuleForm.userEmail" maxlength="30" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </div>
          <el-form-item class="code-pos user-phong" label="验证码" :class="{styleName: isStyleCode}" prop="yzcode">
            <el-input @focus="CodeCheckFocus" @blur="CodeCheckBlur" v-model="passWordRuleForm.yzcode" placeholder="请输入验证码"></el-input>
            <el-button v-if="passWordRuleForm.verification == '短信验证'" class="get-code" @click="getCode" :disabled="codeDisabled" type="primary" plain>{{ btnTxt }}</el-button>
            <el-button v-else-if="passWordRuleForm.verification == '邮箱验证'" class="get-code" @click="getEmail" :disabled="emailDisabled" type="primary" plain>{{ btnTxt }}</el-button>
          </el-form-item>
          <el-button class="subbtn" v-if="passWordRuleForm.verification == '短信验证'" type="primary" @click="NestSubmit('passWordRuleForm')">下一步</el-button>
          <el-button class="subbtn" v-else-if="passWordRuleForm.verification == '邮箱验证'" type="primary" @click="MailSubmit('passWordRuleForm')">下一步</el-button>
        </el-form>
        <el-form v-show="sencode" :model="nextPassWordRuleForm" :rules="nextPassWordNewsRules" ref="nextPassWordRuleForm" class="demo-ruleForm">
          <el-form-item class="code-pos" label="输入密码" :class="{styleName: isStyleOnePass}" prop="password1">
            <el-input type="password" @focus="OnePassFocus" @blur="OnePassBlur" v-model="nextPassWordRuleForm.password1" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
          <el-form-item class="code-pos" label="再次输入" :class="{styleName: isStyleTwoPass}" prop="password2">
            <el-input type="password" @focus="TwoPassFocus" @blur="TwoPassBlur" v-model="nextPassWordRuleForm.password2" placeholder="请再次输入密码" show-password></el-input>
          </el-form-item>
          <el-button class="subbtn" type="primary" @click="RegisterSubmit('nextPassWordRuleForm')">提交</el-button>
        </el-form>
      </div>
    </div>
    <div class="login-footer">
      <div>版权所有 © 2001-2018 中科可控信息产业有限公司  All rights reserved.
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备18044399号-1</a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { baseHost } from '@/assets/js/PublicData.js'
export default {
  name: 'PasswordReset',
  data () {
    // 手机号验证
    var validateMobilePhone = (rule, value, callback) => {
      if (value === '' || value === null || value === undefined) {
        callback(new Error('请输入手机号码'))
      } else {
        if (value !== '') {
          // var reg = /^1[3456789]\d{9}$/
          var regs = value.replace(/\s+/g, '')
          var reg = /^1\s*[34578]\s*(\d\s*){9}$/
          if (!reg.test(regs)) {
            callback(new Error('手机号码错误'))
          } else {
            this.codeDisabled = false
          }
        }
        callback()
      }
    }
    // 邮箱验证
    var validateMobileEmail = (rule, value, callback) => {
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入邮箱地址'))
      } else {
        if (value !== '') {
          var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
          if (!reg.test(value)) {
            callback(new Error('邮箱地址错误'))
          } else {
            this.emailDisabled = false
          }
        }
        callback()
      }
    }
    // 验证密码
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('密码长度最小6位'))
      } else {
        if (this.nextPassWordRuleForm.password2 !== '') {
          this.$refs.nextPassWordRuleForm.validateField('password2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.nextPassWordRuleForm.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    // 验证码验证
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        /* if (value !== '') {
          var reg = /^\d{4}$/
          if (!reg.test(value)) {
            callback(new Error('验证码必须是4位'))
          }
        } */
        callback()
      }
    }
    return {
      JumpTime: '',
      codeTime: '',
      btnTxt: '获取验证码',
      timer: null,
      isStylePhoneCheck: false,
      isStyleMailCheck: false,
      isStyleCode: false,
      isStyleOnePass: false,
      isStyleTwoPass: false,
      codeDisabled: true,
      emailDisabled: true,
      fistNews: true,
      sencode: false,
      passWordRuleForm: {
        verification: '邮箱验证',
        userPhone: null,
        userEmail: this.$route.params.name,
        yzcode: ''
      },
      nextPassWordRuleForm: {
        password1: '',
        password2: ''
      },
      passWordNewsRules: {
        verification: [
          { required: true, message: '请选择验证方式', trigger: 'change' }
        ],
        userPhone: [
          { required: true, validator: validateMobilePhone, trigger: ['blur'] }
        ],
        userEmail: [
          { required: true, validator: validateMobileEmail, trigger: ['blur'] }
        ],
        yzcode: [
          { required: true, validator: validateCode, trigger: ['blur'] }
        ]
      },
      nextPassWordNewsRules: {
        password1: [
          { required: true, validator: validatePass1, trigger: ['blur'] }
        ],
        password2: [
          { required: true, validator: validatePass2, trigger: ['blur'] }
        ]
      }
    }
  },
  created () {
    // console.log('路由传参', this.$route.params.name)
  },
  methods: {
    // 手机号获取验证码
    getCode () {
      axios.get(baseHost.SupplierRegHost + '/api/system/user/sendSmsByPhone?phone=' + this.passWordRuleForm.userPhone).then((res) => {
        if (res.data.code === '200') {
          this.codeTime = 60
          if (this.codeTime > 0) {
            var Cthis = this
            this.codeDisabled = true
            this.btnTxt = this.codeTime + '秒后重新获取'
            const timer = setInterval(function () {
              Cthis.btnTxt = (Cthis.codeTime--) + '秒后重新获取'
              if (Cthis.codeTime <= -1) {
                clearInterval(timer)
                Cthis.btnTxt = '获取验证码'
                Cthis.codeDisabled = false
              }
            }, 1000)
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 邮箱获取验证码
    getEmail () {
      axios.get(baseHost.SupplierRegHost + '/api/system/user/sendMailByMail?mail=' + this.passWordRuleForm.userEmail).then((res) => {
        if (res.data.code === '200') {
          this.codeTime = 60
          if (this.codeTime > 0) {
            var Cthis = this
            this.emailDisabled = true
            this.btnTxt = this.codeTime + '秒后重新获取'
            const timer = setInterval(function () {
              Cthis.btnTxt = (Cthis.codeTime--) + '秒后重新获取'
              if (Cthis.codeTime <= -1) {
                clearInterval(timer)
                Cthis.btnTxt = '获取验证码'
                Cthis.emailDisabled = false
              }
            }, 1000)
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 校验手机号成功--下一步
    NestSubmit (passWordRuleForm) {
      this.$refs[passWordRuleForm].validate((valid) => {
        if (valid) {
          axios.get(baseHost.SupplierRegHost + '/api/system/user/verifySms', {
            params: {
              phone: this.passWordRuleForm.userPhone,
              verificationCode: this.passWordRuleForm.yzcode
            }
          }).then((res) => {
            if (res.data.code === '200') {
              this.fistNews = false
              this.sencode = true
            }
          })
        } else {
          return false
        }
      })
    },
    // 校验邮箱成功--下一步
    MailSubmit (passWordRuleForm) {
      this.$refs[passWordRuleForm].validate((valid) => {
        if (valid) {
          axios.get(baseHost.SupplierRegHost + '/api/system/user/verifyMail', {
            params: {
              mail: this.passWordRuleForm.userEmail,
              verificationCode: this.passWordRuleForm.yzcode
            }
          }).then((res) => {
            if (res.data.code === '200') {
              this.fistNews = false
              this.sencode = true
            }
          })
        } else {
          return false
        }
      })
    },
    // 提交
    RegisterSubmit (nextPassWordRuleForm) {
      this.$refs[nextPassWordRuleForm].validate((valid) => {
        if (valid) {
          // const obj = this.passWordRuleForm.userPhone
          // console.log(obj)
          if (this.passWordRuleForm.verification === '短信验证') {
            axios.get(baseHost.SupplierRegHost + '/api/system/user/updatePasswordByPhone', {
              params: {
                phone: this.passWordRuleForm.userPhone,
                password: this.nextPassWordRuleForm.password1
              }
            }).then((res) => {
              if (res.data.code === '200') {
                this.TimeLogin()
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  offset: 300
                })
              }
            })
          }
          if (this.passWordRuleForm.verification === '邮箱验证') {
            axios.get(baseHost.SupplierRegHost + '/api/system/user/updatePasswordByMail', {
              params: {
                mail: this.passWordRuleForm.userEmail,
                password: this.nextPassWordRuleForm.password1
              }
            }).then((res) => {
              if (res.data.code === '200') {
                this.TimeLogin()
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  offset: 300
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // 3秒后跳转登录页
    TimeLogin () {
      this.JumpTime = 2
      if (this.JumpTime > 0) {
        var that = this
        const JumpTimer = setInterval(function () {
          that.JumpTime--
          if (that.JumpTime <= 0) {
            clearInterval(JumpTimer)
            that.$router.push({
              path: '/Login'
            })
          }
        }, 1000)
      }
    },
    // 跳转登录页
    GoLogin () {
      this.$router.push({
        path: '/Login'
      })
    },
    // 手机号获取焦点与失去焦点提示
    PhoneCheckFocus () {
      this.isStylePhoneCheck = true
    },
    PhoneCheckBlur () {
      this.isStylePhoneCheck = false
    },
    // 邮箱获取焦点与失去焦点提示
    MailCheckFocus () {
      this.isStyleMailCheck = true
    },
    MailCheckBlur () {
      this.isStyleMailCheck = false
    },
    // 验证码获取焦点与失去焦点提示
    CodeCheckFocus () {
      this.isStyleCode = true
    },
    CodeCheckBlur () {
      this.isStyleCode = false
    },
    // 第一次密码获取焦点与失去焦点提示
    OnePassFocus () {
      this.isStyleOnePass = true
    },
    OnePassBlur () {
      this.isStyleOnePass = false
    },
    // 第二次密码获取焦点与失去焦点提示
    TwoPassFocus () {
      this.isStyleTwoPass = true
    },
    TwoPassBlur () {
      this.isStyleTwoPass = false
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '@/assets/css/login.scss';
  .logo-pic, .logo-tit{
    float: left;
  }
  .logo-pic{
    margin: 32px 15px 0 0;
  }
  .logo-tit{
    margin-top: 9px;
  }
  .logo-pic img{
    width: 122px;
  }
  .register-logo{
    height: 80px;
    padding: 0 40px;
  }
  .logo-tit{
    font-size: 24px;
    color: #fff;
  }
  .register{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('~@/assets/image/login/bg1.png') no-repeat center top;
    background-size: cover;
  }
  .register-cont{
    padding: 60px 40px;
    width: 402px;
    height: 340px;
    position: absolute;
    left: calc(50% + 286px);;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 4px;
    background: #fff;
  }
  .register-tit{
    margin-bottom: 50px;
  }
  .res-line{
    width: 5px;
    height: 21px;
    background: #409EFF;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 2px;
  }
  .register-news .el-form-item{
    margin-bottom: 50px;
  }
  .left-span{
    position: relative;
    float: left;
    margin-left: 14px;
    font-size: 18px;
    color: #666;
  }
  .right-span span{
    color: #666;
}
  .right-span{
    float: right;
    font-size: 14px;
    color: #409EFF;
    cursor: pointer;
  }
  .yz-flot .yz-ver-left{
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .yz-flot{
    position: relative;
  }
  .ver-left, .el-select{
    width: 100%;
  }
  .user-phong{
    position: relative;
    .el-button{
      min-height: 38px;
      border: none;
      min-height: 30px;
      border: none;
      color: #409EFF;
      background: none;
    }
  }
  .get-code{
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    padding: 0 10px;
    border-radius: 3px;
  }
  .subbtn{
    width: 100%;
    margin: 10px 0 0;
    font-size: 18px;
  }
  .check-box{
    margin-bottom: 0;
  }
  .login-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 55px;
    text-align: center;
    color: #666;
    font-size: 14px;
    padding-top: 25px;
    a{
      color: #007dff;
    }
  }

  .center-opcity{
    height: 240px;
    width: 100%;
    background: rgba(245, 249, 252, 0.4);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .tabe-tit1{
    font-size: 48px;
    color: #040d18;
  }
  @media screen and (max-width: 1440px){
    .tabe-tit1{
      font-size: 44px;
    }
  }
  .tabe-tit2{
    font-size: 36px;
    color: #333;
  }
  .pos-tit{
    position: absolute;
    right: 582px;
    top: 164px;
    width: 482px;
    text-align: right;
  }
  .styleName :deep(.el-form-item__error){
    display: none;
  }
  .styleName :deep(.el-input__inner){
    border-color: #F56C6C;
  }
</style>
